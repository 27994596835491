import {
  Typography,
  Row,
  Col,
  Card,
  Skeleton,
  Descriptions,
  Empty,
  Tag,
  Image,
  Input,
  Button,
  Pagination,
} from "antd";
import { useEffect, useState, useCallback } from "react";
import { CONTAINER_API } from "../api/api";
import { DoubleRightOutlined } from "@ant-design/icons";
import { useNavigate } from "react-router-dom";

const { Search } = Input;
const { Title } = Typography;

const Containers = () => {
  const [data, setData] = useState([]);
  const [loading, setLoading] = useState(false);
  const [lastFetchTime, setLastFetchTime] = useState(null);
  const [value, setValue] = useState("");
  const [params, setParams] = useState({
    page: 1, // Initial page
    limit: 50, // Initial page size
  });

  const navigate = useNavigate();

  // Fetch data function with pagination
  const getData = useCallback(() => {
    setLoading(true);
    CONTAINER_API.getContainers(params)
      .then((response) => {
        setData(response.data);
      })
      .catch((error) => {
        console.error("Failed to fetch containers:", error);
      })
      .finally(() => {
        setLoading(false);
      });
  }, [params]); // Trigger getData when `params` changes

  useEffect(() => {
    getData();
  }, [getData, params]);

  return (
    <div>
      <Title level={4}>Containers</Title>
      {!loading && (
        <Row gutter={[32, 32]}>
          <Col sm={18} xs={24}>
            <Search
              placeholder="Search containers using container number"
              size="large"
              enterButton
              value={value}
              onChange={(event) => setValue(event.target.value)}
              onSearch={(value) => {
                let containers = [...data];
                const regex = new RegExp(value, "i");
                containers = data?.results.filter(function (item) {
                  let container = item.container_number;
                  return (
                    regex.test(container.container_number) ||
                    regex.test(container.igm_number)
                  );
                });
                setData(containers);
              }}
            />
          </Col>
          <Col sm={6} xs={24}>
            <Button
              block
              type="primary"
              danger
              size="large"
              onClick={() => {
                setData([]);
                getData(true);
                setValue("");
              }}
              disabled={!value}
            >
              Reset
            </Button>
          </Col>
        </Row>
      )}
      {loading && <Skeleton paragraph={{ rows: 10 }} />}

      <Row gutter={[32, 32]} style={{ marginTop: 30 }}>
        {data?.results?.map((container, index) => (
          <Col key={container.id} xs={24}>
            <div>
              <Card
                headStyle={{ border: 0 }}
                bordered={false}
                title={container?.container_number?.container_number}
                cover={
                  container?.images.length > 0 ? (
                    <Image
                      src={container?.images[0]}
                      height={200}
                      style={{ border: "2px solid blue" }}
                      title="Container Image"
                    />
                  ) : null
                }
                extra={<DoubleRightOutlined />}
                hoverable
                onClick={() =>
                  navigate(`/containers/${container?.container_number?._id}`, {
                    state: { container },
                  })
                }
              >
                <Descriptions column={1}>
                  <Descriptions.Item label="IGM Number">
                    {container?.container_number?.igm_number}
                  </Descriptions.Item>
                  <Descriptions.Item label="IGM Date">
                    {container?.container_number?.igm_date}
                  </Descriptions.Item>
                  <Descriptions.Item label="Reason of not scanning">
                    {container?.reason}
                  </Descriptions.Item>
                  <Descriptions.Item label="Examined Image">
                    {container?.examined_images.length > 0 ? (
                      <Tag color="green">Uploaded</Tag>
                    ) : (
                      <Tag color="red">Not Uploaded</Tag>
                    )}
                  </Descriptions.Item>
                </Descriptions>
              </Card>
            </div>
          </Col>
        ))}
      </Row>
      {loading && data.length > 0 && (
        <div className="text-center">
          <span>Loading more containers...</span>
        </div>
      )}

      {/* Pagination */}
      <Pagination
        align="center"
        size="default"
        current={params.page}
        pageSize={50}
        total={data.pagination?.totalCount || 0} // Total items count
        onChange={(value) => {
          console.log("value", value);
          setParams({ ...params, page: value });
        }}
        style={{ textAlign: "center", marginTop: 20 }}
        showSizeChanger={false}
      />
    </div>
  );
};

export default Containers;
